import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

const query = graphql`
  query GetSiteMetadata {
    site {
      siteMetadata {
        title
        author
        description
        siteUrl
        social {
          twitter
        }
        image
      }
    }
  }
`;

function SEO({ meta, image, title, description, slug, lang = "pl" }) {
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const { siteMetadata } = data.site;
        const metaDescription = description || siteMetadata.description;
        const metaImage = `${siteMetadata.siteUrl}${siteMetadata.image}`;
        const url = `${siteMetadata.siteUrl}${slug ? slug : ""}`;
        const ogCustomImagePath = `${siteMetadata.siteUrl}${image}`;

        return (
          <Helmet
            title={title || siteMetadata.title}
            htmlAttributes={{ lang }}
            meta={[
              {
                name: "title",
                content: title || siteMetadata.title,
              },
              {
                name: "description",
                content: metaDescription,
              },
              {
                property: "og:url",
                content: url,
              },
              {
                property: "og:title",
                content: title || siteMetadata.title,
              },
              {
                property: "og:description",
                content: metaDescription,
              },
              {
                property: "robots",
                content: "index, follow",
              },
              {
                name: "twitter:card",
                content: "summary",
              },
              {
                name: "twitter:creator",
                content: siteMetadata.social.twitter,
              },
              {
                name: "twitter:title",
                content: title || siteMetadata.title,
              },
              {
                name: "twitter:description",
                content: metaDescription,
              },
              {
                name: "format-detection",
                content: "telephone=no",
              },
              {
                name: "format-detection",
                content: "address=no",
              },
              {
                name: "format-detection",
                content: "email=no",
              },
              {
                name: "format-detection",
                content: "date=no",
              },
            ]
              .concat(
                image
                  ? [
                      {
                        property: "og:image",
                        content: ogCustomImagePath,
                      },
                      {
                        name: "twitter:image",
                        content: ogCustomImagePath,
                      },
                    ]
                  : [
                      {
                        property: "og:image",
                        content: metaImage,
                      },
                      {
                        name: "twitter:image",
                        content: metaImage,
                      },
                    ]
              )
              .concat(meta ? meta : [])}
          />
        );
      }}
    />
  );
}

export default SEO;
