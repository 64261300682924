import React from "react";

import claim from "../../images/claim.svg";
import logoHazay from "../../images/logoHazay.svg";

import { socialLinks } from "./NavBar";

import { Link } from "gatsby";

import "./Footer.css";

const Footer = (props) => {
  return (
    <div>
      <div className="footer-container">
        <div className="footer-image">
          <img
            src={claim}
            style={{ maxHeight: "130px", maxWidth: "100%" }}
            alt="Hazay Logo"
          />
        </div>
        <div style={{ flex: "0.75", color: "white" }}>
          <h3 style={{ margin: 0, marginBottom: "1rem" }}>Kontakt</h3>
          <table
            style={{ fontSize: "12px", color: "white", textDecoration: "none" }}
          >
            <tbody>
              <tr>
                <th className="footer-table-header">Marcin Grausch</th>
                <td className="footer-table-row">+48 509 668 294</td>
              </tr>
              <tr>
                <th className="footer-table-header">Kuba Garstkiewicz</th>
                <td className="footer-table-row">+48 608 463 845</td>
              </tr>
              <tr>
                <th className="footer-table-header">e-mail</th>
                <td className="footer-table-row">info@hazaybikes.com</td>
              </tr>
              <tr>
                <th className="footer-table-header">adres</th>
                <td className="footer-table-row">
                  Święty Marcin 29/8 61-806, Poznań, Polska
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="last-row-footer">
        <div className="footer-nav">
          <div className="footer-nav-item">
            <Link
              to="/regulamin"
              activeClassName="active"
              className="footer-nav-links"
              partiallyActive={true}
            >
              Polityka Prywatności
            </Link>
          </div>
          <div className="footer-nav-item">
            <Link
              to="/contact"
              activeClassName="active"
              className="footer-nav-links"
              partiallyActive={true}
            >
              Kontakt
            </Link>
          </div>
        </div>
        <div className="footer-logo">
          <img
            src={logoHazay}
            style={{ height: "30px", marginBottom: 0 }}
            alt="Hazay Logo"
          />
        </div>

        <div className="social-links-footer">{socialLinks()}</div>
      </div>
    </div>
  );
};

export default Footer;
